import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      'Space Grotesk',
      'Manrope',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: 'Space Grotesk',
      fontSize: '60px',
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: '-0.05em',
      '@media (max-width:960px)': {
        fontSize: '45px',
      },
      '@media (max-width:600px)': {
        fontSize: '30px',
      },
    },
    h2: {
      fontFamily: 'Space Grotesk',
      fontSize: '34px',
      fontWeight: 500,
      lineHeight: 1.1,
      letterSpacing: '-0.05em',
      '@media (max-width:960px)': {
        fontSize: '28px',
      },
      '@media (max-width:600px)': {
        fontSize: '24px',
      },
    },
    subtitle1: {
      fontFamily: 'Space Grotesk',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: '-0.05em',
      '@media (max-width:960px)': {
        fontSize: '18px',
      },
      '@media (max-width:600px)': {
        fontSize: '16px',
      },
    },
    body1: {
      fontFamily: 'Manrope',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.8,
      letterSpacing: '0.015em',
      '@media (max-width:960px)': {
        fontSize: '15px',
      },
      '@media (max-width:600px)': {
        fontSize: '14px',
      },
    },
  },
  palette: {
    primary: {
      main: '#E4DCAD',
      light: '#F2ECCE',
      dark: '#C4BC8D',
    },
    secondary: {
      main: '#DD4744',
      light: '#E16764',
      dark: '#B83936',
    },
    background: {
      default: '#141414',
      paper: '#1A1A1A',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#CCCCCC',
      disabled: '#999999',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-activeText": {
            fontFamily: "Manrope",
            fontWeight: 600,
            fontSize: "14px",
            letterSpacing: "-0.5px",
            lineHeight: "19.12px",
            padding: "16px 20px",
            color: "#ffffff",
            position: "relative",
            "@media (max-width:960px)": {
              fontSize: "13px",
              padding: "14px 18px",
            },
            "@media (max-width:600px)": {
              fontSize: "12px",
              padding: "12px 16px",
            },
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "6px",
              left: "20px",
              right: "20px",
              height: "2px",
              backgroundColor: "#E4DCAD",
            },
          },
          "&.MuiButton-filled": {
            fontFamily: "Manrope",
            fontWeight: 600,
            fontSize: "14px",
            letterSpacing: "-0.5px",
            lineHeight: "19.12px",
            width: "fit-content",
            padding: "16px 20px",
            color: "#141414",
            backgroundColor: "#E4DCAD",
            borderRadius: "60px",
            "@media (max-width:960px)": {
              fontSize: "13px",
              padding: "14px 18px",
            },
            "@media (max-width:600px)": {
              fontSize: "12px",
              padding: "12px 16px",
            },
            "&:hover": {
              backgroundColor: "#F2ECCE",
            },
          },
          "&.MuiButton-orderNow": {
            fontFamily: "Manrope",
            fontWeight: 700,
            fontSize: "14px",
            letterSpacing: "-0.5px",
            lineHeight: "19.12px",
            width: "fit-content",
            padding: "16px 20px",
            color: "#F2ECCE",
            backgroundColor: "#DD4744",
            borderRadius: "60px",
            "@media (max-width:960px)": {
              fontSize: "13px",
              padding: "14px 18px",
            },
            "@media (max-width:600px)": {
              fontSize: "12px",
              padding: "12px 16px",
            },
            "&:hover": {
              backgroundColor: "#E16764",
            },
          },
          "&.MuiButton-submit": {
            fontFamily: "Manrope",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "19.12px",
            width: "fit-content",
            padding: "18px 26px",
            color: "#FFFFFF",
            backgroundColor: "#0A6FD4",
            borderRadius: "50px",
            "@media (max-width:960px)": {
              fontSize: "13px",
              padding: "16px 22px",
            },
            "@media (max-width:600px)": {
              fontSize: "12px",
              padding: "14px 20px",
            },
            "&:hover": {
              backgroundColor: "#2185E5",
            },
            "&:disabled": {
              backgroundColor: "#dddddd",
            },
          },
          "&.MuiButton-text": {
            fontFamily: "Manrope",
            fontWeight: 600,
            fontSize: "14px",
            letterSpacing: "-0.5px",
            lineHeight: "19.12px",
            padding: "16px 20px",
            color: "#ffffff",
            position: "relative",
            "@media (max-width:960px)": {
              fontSize: "13px",
              padding: "14px 18px",
            },
            "@media (max-width:600px)": {
              fontSize: "12px",
              padding: "12px 16px",
            },
            "&:hover": {
              padding: "16px 20px",
              borderRadius: "0px",
              "@media (max-width:960px)": {
                padding: "14px 18px",
              },
              "@media (max-width:600px)": {
                padding: "12px 16px",
              },
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "6px",
                left: "20px",
                right: "20px",
                height: "2px",
                backgroundColor: "#E4DCAD",
              },
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#1A1A1A',
          borderRadius: '16px',
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
