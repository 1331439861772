import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Stack } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import { lazy, Suspense, useEffect, useState } from "react";
import theme from './components/theme';

const Navbar = lazy(() => import("./components/Navbar"));
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const AboutUbo = lazy(() => import("./pages/AboutUbo"));
const OurServices = lazy(() => import("./pages/OurServices"));
const HappyClients = lazy(() => import("./pages/HappyClients"));
const GetInTouch = lazy(() => import("./pages/GetInTouch"));
const Footer = lazy(() => import("./pages/Footer"));
const Download = lazy(() => import("./pages/Downloads"));

function App() {
  const [activeSection, setActiveSection] = useState("home");
  const [showNavbar, setShowNavbar] = useState(true);

  const sections = [
    { id: "home", title: "Home" },
    { id: "about", title: "About us" },
    { id: "products", title: "Products" },
    { id: "services", title: "Our Services" },
    { id: "contact", title: "Contact us" },
  ];

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setShowNavbar(scrollTop < 200);

    const sectionPositions = sections.map((section) => {
      const element = document.getElementById(section.id);
      if (!element) return null;
      return {
        id: section.id,
        offsetTop: element.offsetTop,
        offsetHeight: element.offsetHeight,
      };
    }).filter(Boolean);

    const active = sectionPositions.find(
      (section) =>
        scrollTop >= section.offsetTop - 50 &&
        scrollTop < section.offsetTop + section.offsetHeight - 50
    );

    if (active) {
      setActiveSection(active.id);
    }
  };

  const handleMouseMove = (event) => {
    if (window.scrollY < 200 || event.clientY < 100) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Suspense
            fallback={
              <Stack alignItems="center" justifyContent="center" height="100vh">
                <img 
                  src="/images/loading.gif" 
                  alt="Loading..."
                  style={{ height: "400px", width: "400px" }} 
                  loading="lazy"
                />
              </Stack>
            }
          >
            <Stack maxWidth="100vw" overflowX="hidden">
              <Routes>
                <Route 
                  path="/" 
                  element={
                    <>
                      {showNavbar && <Navbar sections={sections} activeSection={activeSection} />}
                      <Home id="home" />
                      <About id="about" />
                      <AboutUbo id="products" />
                      <OurServices id="services" />
                      <HappyClients id="services" />
                      <GetInTouch id="contact" />
                      <Footer />
                    </>
                  } 
                />
                <Route path="/download" element={<Download />} />
              </Routes>
            </Stack>
          </Suspense>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
